import React, { useState, useContext, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { AuthContext } from "../context/auth"
import firebase from "gatsby-plugin-firebase"
//import "firebase/firestore"
import VolverLogin from "../components/VolverLogin"
import useAuditorios from "../hooks/useAuditorios"
import {
  injectIntl,
  // FormattedMessage,
  // changeLocale,
  Link,
  navigate,
} from "gatsby-plugin-intl"
import axios from "axios"
import {
  Button,
  Select,
  InputLabel,
  MenuItem,
  //  Avatar,
  //  ListItemAvatar,
  ListItem,
  ListItemText,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Switch,
  Divider,
  Typography,
  makeStyles,
  Container,
} from "@material-ui/core"
//import { graphql } from "gatsby"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: "none",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: 8,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: 8,
  },
}))
const CargadorAuditorios = ({ intl, data }) => {
  const resultado = useAuditorios()
  const [auditorios, guardarAuditorios] = useState([])
  const [video2Activo, setVideo2Activo] = useState(false)
  const [video3Activo, setVideo3Activo] = useState(false)

  const [auditorioEditar, setAuditorioEditar] = useState({
    nombre: "",
    video_principal: "",
    video_2: "",
    video_3: "",
    // video_2_activo: false,
    // video_3_activo: false,
  })

  useEffect(() => {
    guardarAuditorios(resultado)
  }, [])
  const [modificarAuditorio, setModificarAuditorio] = useState({
    nombre: "",
  })
  const mostrar = () => {
    if (modificarAuditorio.nombre !== "") {
      let filas = {}

      firebase
        .firestore()
        .collection("auditorios")
        .doc(modificarAuditorio.nombre)
        .get()
        .then(doc => {
          if (doc.exists) {
            // console.log("Document data:", doc.data())
            filas = {
              // REVISAR SI ALGUN DATO VIENE VACIO QUE PINTA EN PANTALLA
              nombre: modificarAuditorio,
              video_principal: doc.data().video_principal,
              video_2: doc.data().video_2,
              video_3: doc.data().video_3,
            }
            setAuditorioEditar(filas)
            setVideo2Activo(doc.data().video_2_activo)
            setVideo3Activo(doc.data().video_3_activo)
          } else {
            // doc.data() will be undefined in this case
            //  console.log("No such document!")
            filas = {
              // REVISAR SI ALGUN DATO VIENE VACIO QUE PINTA EN PANTALLA
              nombre: modificarAuditorio,
              video_principal: "",
              video_2: "",
              video_3: "",
            }
            setAuditorioEditar(filas)
            setVideo2Activo(false)
            setVideo3Activo(false)
          }
        })
        .catch(error => {
          console.log("Error getting document:", error)
        })
    }
  }
  useEffect(() => {
    mostrar()
  }, [modificarAuditorio])

  const { user } = useContext(AuthContext)

  const handleChange = e => {
    setAuditorioEditar({
      ...auditorioEditar,
      [e.target.name]: e.target.value,
    })
  }
  const classes = useStyles()

  const handleSubmit = async e => {
    e.preventDefault()
    setAuditorioEditar({ ...auditorioEditar, error: null })
    try {
      firebase
        .firestore()
        .collection("auditorios")
        .doc(modificarAuditorio.nombre)
        .set({
          video_principal: auditorioEditar.video_principal,
          video_2: auditorioEditar.video_2,
          video_3: auditorioEditar.video_3,
          video_2_activo: video2Activo,
          video_3_activo: video3Activo,
        })
        .then(alert(intl.formatMessage({ id: "cambiosguardados" })))
    } catch (err) {
      setAuditorioEditar({ ...auditorioEditar, error: err.message })
    }
  }

  return (
    <>
      {user != null &&
      (user.email === "gaston@fassilavalle.com.ar" ||
        user.email === "fiorella@virtualeventweb.com" ||
        user.email === "streaming@virtualeventweb.com") ? (
        <Layout>
          <div
            style={{
              width: "20%",
              position: "absolute",
              left: "0%",
              top: "5%",
            }}
          >
            <h5 style={{ textAlign: "center", width: "100%", marginTop: 10 }}>
              Listado auditorios
            </h5>
            <Divider />
            {auditorios.map(auditorio => (
              <ListItem
                button
                key={auditorio.id}
                onClick={() => setModificarAuditorio(auditorio)}
              >
                <ListItemText>{auditorio.nombre}</ListItemText>
              </ListItem>
            ))}
          </div>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                {modificarAuditorio.nombre}
              </Typography>
              {modificarAuditorio.nombre !== "" ? (
                <form
                  className={classes.form}
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="video_principal"
                    label="Video principal"
                    type="text"
                    id="video_principal"
                    value={auditorioEditar.video_principal}
                    onChange={handleChange}
                  />
                  {modificarAuditorio.video_en_activado ? (
                    <div>
                      <InputLabel id="segundocanal" style={{ padding: 0 }}>
                        Segundo canal activado
                      </InputLabel>
                      <Switch
                        checked={video2Activo}
                        onChange={e => setVideo2Activo(e.target.checked)}
                        name="video_2_activo"
                        inputProps={{ "aria-label": "Video 2 activo" }}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="video_2"
                        label="Canal 2 de video"
                        type="text"
                        id="video_2"
                        value={auditorioEditar.video_2}
                        onChange={handleChange}
                      />
                    </div>
                  ) : (
                    <div>
                      Canal 2 deshabilitado.
                      <br />
                      Comuniquese con View para activar otro canal.
                    </div>
                  )}
                  <br />
                  {modificarAuditorio.video_pt_activado ? (
                    <div>
                      <InputLabel id="tercercanal" style={{ padding: 0 }}>
                        Tercer canal activado
                      </InputLabel>
                      <Switch
                        checked={video3Activo}
                        onChange={e => setVideo3Activo(e.target.checked)}
                        name="video_3_activo"
                        inputProps={{ "aria-label": "Video 3 activo" }}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="video_3"
                        label="Canal 3 de video"
                        type="text"
                        id="video_3"
                        value={auditorioEditar.video_3}
                        onChange={handleChange}
                      />
                    </div>
                  ) : (
                    <div>
                      Canal 3 deshabilitado.
                      <br />
                      Comuniquese con View para activar otro canal.
                    </div>
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Guardar
                  </Button>
                </form>
              ) : (
                <div>Por favor elija un auditorio para modificar</div>
              )}
            </div>
          </Container>
        </Layout>
      ) : (
        <VolverLogin />
      )}
    </>
  )
}

export default injectIntl(CargadorAuditorios)
